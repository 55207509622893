.container {
    width: 100%;
    height: 100%;

    position: relative;
}

.benerImage {
    width: 100vw;
    height: 50vh;

    @media (max-width: 900px) {
        margin-top: 15vw;
        height: 40vw;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: rgba(0, 0, 0, 0.5); // 50% 투명도
    pointer-events: none;

    @media (max-width: 900px) {
        top: 15vw;
        height: 40vw;
    }
}

.contents {
    position: absolute;
    top: 25vh;
    left: 50%;
    transform: translate(-50%, -7vh);
    text-align: center;
    color: #FFFFFF;

    width: 80%;

    @media (max-width: 900px) {
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .title {
        margin-bottom: 1vw;

        font-family: "Noto Sans KR", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-style: normal;
        font-size: 3vw;

        @media (max-width: 900px) {
            font-size: 7vw;
        }
    }

    .text {
        margin: 0.5vw;
        font-size: 1.2vw;

        span {
            font-weight: 800;
        }

        @media (max-width: 900px) {
            margin: 1vw;
            font-size: 3vw;
        }
    }
}