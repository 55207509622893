.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bener {
    width: 100vw;
    height: auto;
}

.textBox{
    margin: 4vw 0 0 0;
    text-align: center;
    font-size: 2.5vw;

    div:nth-of-type(1) {
        font-weight: 200;
    }

    div:nth-of-type(2) {
        margin-top: 1vw;
        font-weight: 800;
        font-size: 2.5vw;
        margin-bottom: 2vw;
        color: #910250;
    }
}

.img3 {
    margin: 2vw 0 0 0 ;
    width: 80%;

    @media (max-width: 900px) {
        width: 90%;
    }
}

.img1 {
    margin: 3vw 0 3vw 0 ;
    width: 70%;

    @media (max-width: 900px) {
        margin: 3vw 0 3vw 0 ;
        width: 90%;
    }
}

.img2 {
    margin: 3vw 0 3vw 0 ;
    width: 60%;

    @media (max-width: 900px) {
        margin: 3vw 0 3vw 0 ;
        width: 90%;
    }
}

.tableContainer {
    display: flex;
    flex-direction: row;

    margin-top: 2vw;
    width: 80%;

    @media (max-width: 900px) {
        width: 90%;
    }

    .tableImg{
        @media (max-width: 900px) {
            width: 15vw;
        }
    }

    .projectTable {
        width: 100%;
        border-collapse: collapse;

        .label {
            width: 8vw;
            background-color: #fffbf4;
            text-align: center;
            font-size: 1.1vw;
            color: #ad8844;
            
            @media (max-width: 900px) {
                width: 15vw;
                padding: 1.5vw 0;
                font-size: 3vw;
            }
        }

        td {
            border: 1px solid #d3c7bb;
            font-size: 1vw;

            @media (max-width: 900px) {
                font-size: 2.5vw;
                line-height: 4vw;
            }
        }

        .contents {
            padding: 0 2vw 0 1vw;

            @media (max-width: 900px) {
                padding: 0 2vw 0 2vw;
            }
        }
    }
}

.commonBox {
    margin: 2vw 0;
    width: 80%;
    background-color: #f8f4f0;
    border: 1px solid #e9e2da;

    @media (max-width: 900px) {
        width: 90%;
    }

    .notice {
        padding: 0 1vw;
        line-height: 1.1vw;
        font-size: 1vw;
        color: #ada8a2;

        @media (max-width: 900px) {
            padding: 0 3vw;
            font-size: 2.5vw;
            line-height: 3vw;
            text-indent: -2vw;
        }
    }
  
    .notice:nth-of-type(1) {
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(2),
    .notice:nth-of-type(3),
    .notice:nth-of-type(4),
    .notice:nth-of-type(5) {
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(6) {
        padding-bottom: 1vw;
    }
}