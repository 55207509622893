.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    background-color: #eeefef;

    width: 100vw;
    height: 16vw;

    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100vw;
        height: 100%;
    }

    img {
        margin: 4vw 0 0 3vw;
        width: 10vw;

        @media (max-width: 900px) {
            margin: 6vw 0 0 0;
            width: 20vw;
        }
    }

    .textContainer {
        margin: 4vw 0 0 3vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: 900px) {
            margin: 4vw 0 0 0;
        }
    }
}

.innerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 80%;

    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 90%;
    }

    .text1 {
        font-weight: 200;
        font-style: normal;
        font-size: 1vw;
        color: #000000;
        line-height: 1.5vw;

        @media (max-width: 900px) {
            font-size: 2vw;
            line-height: 4vw;
            text-indent: -2vw;
        }
    }

    .text2 {
        margin-top: 2vw;
        font-size: 0.9vw;
        color: #000000;

        @media (max-width: 900px) {
            padding-bottom: 12vw;
            margin-top: 4vw;
            font-size: 2vw;
        }
    }

    a {
        text-align: center;
        font-family: "Paperlogy-8ExtraBold";
        text-decoration: none;
        font-size: 1.8vw;
        font-weight: 500;
        color: #000000;

        @media (max-width: 900px) {
            margin-top: 5vw;
            font-size: 4vw;
        }
    }
}