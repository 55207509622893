.leftContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30vw;
    position: relative;
    overflow: hidden; // 부모 요소 경계를 넘지 않도록 설정

    .backgroundContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        .backgroundImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2) 100%);
            z-index: 1;
        }
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2vw;
        margin-left: 6vw;
        z-index: 2;
        color: #fff6ea;
        font-size: 4vw;

        img {
            width: 13vw;
        }
    }

    .subTextleft {
        display: flex;
        flex-direction: column;
        justify-items: flex-end;
        margin-right: 5vw;
        text-align: right;
        font-size: 3.5vw;
        z-index: 2;

        .subText1{
            font-size: 3vw;
            font-weight: 600;
        }
        .subText2{
            font-size: 3vw;
            margin: 1vw 0 0 0 ;
        }
    }

    // 상태별 스타일링
    &.unClicked,
    &.onClicked {
        // 가상 요소를 이용한 채워지는 배경 애니메이션
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0; // 오른쪽에서 왼쪽으로 채워지도록 설정
            width: 100%;
            height: 100%;
            background-color: #fff6ea;
            z-index: 0; // 이미지보다 아래에 위치
            transform: translateX(100%);
            transition: transform 0.7s ease; // 배경색이 부드럽게 채워짐
        }
    }

    &.onClicked {
        &::before {
            border-top: 0.1px solid #321c0090;
            transform: translateX(0); // 클릭 시 배경색이 오른쪽에서 왼쪽으로 채워짐
        }

        .left {
            color: #000000; // 클릭 시 텍스트 색상 변경
        }
    }
}

.rightContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30vw;
    position: relative;
    overflow: hidden; // 부모 요소 경계를 넘지 않도록 설정

    .backgroundContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        .backgroundImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2) 100%);
            z-index: 1;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2vw;
        margin-right: 6vw;
        z-index: 2;
        color: #fff6ea;
        font-size: 4vw;

        img {
            width: 13vw;
        }
    }

    .subTextright {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        margin-left: 5vw;
        text-align: left;
        font-size: 3.5vw;
        z-index: 2;

        .subText1{
            font-size: 3vw;
            font-weight: 600;
        }
        .subText2{
            font-size: 3vw;
            margin: 1vw 0 0 0 ;
        }
    }

    // 상태별 스타일링
    &.unClicked,
    &.onClicked {
        // 가상 요소를 이용한 채워지는 배경 애니메이션
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0; // 오른쪽에서 왼쪽으로 채워지도록 설정
            width: 100%;
            height: 100%;
            background-color: #fff6ea;
            z-index: 0; // 이미지보다 아래에 위치
            transform: translateX(-100%);
            transition: transform 0.7s ease; // 배경색이 부드럽게 채워짐
        }
    }

    &.onClicked {
        &::before {
            border-top: 0.1px solid #321c0090;
            transform: translateX(0); // 클릭 시 배경색이 오른쪽에서 왼쪽으로 채워짐
        }

        .right {
            color: #000000; // 클릭 시 텍스트 색상 변경
        }
    }
}