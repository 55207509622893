.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 48vw;

    background-color: rgba(0, 0, 0, 0.4);

    z-index: 1000;

    /* 애니메이션 추가 */
    animation: slideIn 0.5s forwards; // 애니메이션을 추가

    @media (max-width: 900px) {
        background-color: rgba(0, 0, 0, 0.4);
        width: 100vw;
        height: 135vw;
    }

    @keyframes slideIn {
        from {
            transform: translateX(-100%); // 왼쪽에서 시작
            opacity: 0; // 투명하게 시작
        }
        to {
            transform: translateX(0); // 원래 위치로
            opacity: 1; // 완전히 보이게
        }
    }
}

.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .image1 {
        height: 35rem;
        width: auto;

        @media (max-width: 900px) {
            width: 50vw;
            height: auto;
        }
    }
}

.closeBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    color: #FFFFFF;
    font-family: "Noto Sans KR", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 1vw; /* PC 버전 */

    cursor: pointer;
    
    @media (max-width: 900px) {
        font-size: 3vw; /* 모바일 화면에서 크기 좀 더 크게 설정 */
    }

    @media (max-width: 650px) {
        font-size: 16px; /* 더 작은 화면에서 px로 고정 크기 설정 */
    }
}

.closeBtn:nth-child(1) {
    border-right: 1px solid #FFFFFF;
}