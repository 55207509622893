.container {
    position: relative;
    width: 100%; /* 또는 원하는 너비를 설정 */
    height: auto; /* 이미지에 맞게 높이 자동 조정 */
    
    img {
        width: 100%; /* 이미지가 컨테이너의 전체 너비를 차지 */
        height: auto; /* 이미지의 비율 유지 */
        display: block; /* 이미지 아래에 여백이 생기는 것을 방지 */
    }

    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    .contents {
        position: absolute; /* 부모를 기준으로 위치 절대 지정 */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 90%;

        text-align: center;
        font-family: "HakgyoansimBareonbatangA";
        color: #FFFFFF;

        .title {
            line-height: 2.5vw;
            font-size: 1.7vw;

            @media (max-width: 900px) {
            line-height: 4.5vw;
            font-size: 3.5vw;
            font-weight: 400;
            }
        }

        .text {
            margin-top: 2vw;
            line-height: 1.5vw;
            font-size: 1vw;

            @media (max-width: 900px) {
                line-height: 3vw;
                font-size: 2vw;
                font-weight: 200;
                }
        }
    }
}
